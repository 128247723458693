import { z } from 'zod'

const envSchema = z.object({
  NODE_ENV: z.enum(['development', 'production', 'test']),
  VERCEL_URL: z.string().optional(),
  PORT: z.string().optional(),
  client: z.object({
    DATOCMS_ACCESS_TOKEN: z.string(),
    DATOCMS_GRAPHQL_URL: z.string(),
    LEDERNE_API_URL: z.string(),
    SENTRY_DSN: z.string(),
  }),
})

const envVariables = {
  NODE_ENV: process.env.NODE_ENV,
  VERCEL_URL: process.env.VERCEL_URL,
  PORT: process.env.DEVELOPMENT_PORT,
  client: {
    DATOCMS_ACCESS_TOKEN: process.env.NEXT_PUBLIC_DATOCMS_ACCESS_TOKEN,
    DATOCMS_GRAPHQL_URL: process.env.NEXT_PUBLIC_DATOCMS_GRAPHQL_URL,
    LEDERNE_API_URL: process.env.NEXT_PUBLIC_LEDERNE_API_URL,
    SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
}

export const env = envSchema.parse(envVariables)
