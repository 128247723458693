import type { DefaultSeoProps } from 'next-seo'

export const defaultSeo: DefaultSeoProps = {
  title: 'Lederne',
  titleTemplate: '%s - Kernekvadranten',
  description:
    'Kernekvadranten er et udviklingsværktøj, der giver dig en dybere indsigt i, hvordan din adfærd påvirker andre og omvendt. En kernekvadrant tager udgangspunkt i dine styrker og det udviklingspotentiale, der ligger i at forstå dem fra flere vinkler.',
  openGraph: {
    locale: 'da',
    siteName: 'Kernekvadranten',
    title: 'Kernekvadranten',
    type: 'website',
    url: 'https://kernekvadranten.lederne.dk/',
    description:
      'Kernekvadranten er et udviklingsværktøj, der giver dig en dybere indsigt i, hvordan din adfærd påvirker andre og omvendt. En kernekvadrant tager udgangspunkt i dine styrker og det udviklingspotentiale, der ligger i at forstå dem fra flere vinkler.',
    images: [
      {
        url: 'https://kernekvadranten.lederne.dk/images/social/og-rectangle.png',
        alt: 'Kernekvadranten',
        width: 2400,
        height: 1260,
      },
      {
        url: 'https://kernekvadranten.lederne.dk/images/social/og-square.png',
        alt: 'Kernekvadranten',
        width: 2400,
        height: 2400,
      },
    ],
  },
  twitter: {
    cardType: 'summary_large_image',
  },
}
