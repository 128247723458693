import { useState } from 'react'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import clsx from 'clsx'
import { AnimatePresence } from 'framer-motion'
import type { AppProps } from 'next/app'
import localFont from 'next/font/local'
import Script from 'next/script'
import { DefaultSeo } from 'next-seo'

import { defaultSeo } from '@config/default-seo'

import '@styles/global.css'

export const fontLufga = localFont({
  variable: '--font-lufga',
  display: 'swap',
  src: [
    {
      path: '../../public/fonts/Lufga-300.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Lufga-300i.woff2',
      weight: '300',
      style: 'italic',
    },
    {
      path: '../../public/fonts/Lufga-400.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Lufga-400i.woff2',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../../public/fonts/Lufga-500.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Lufga-500i.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../../public/fonts/Lufga-600.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Lufga-600i.woff2',
      weight: '600',
      style: 'italic',
    },
  ],
})

export const fontPTSerif = localFont({
  variable: '--font-pt-serif',
  src: [
    {
      path: '../../public/fonts/PTSerif-700i.woff2',
      weight: '700',
      style: 'italic',
    },
  ],
})

export const fontClasses = clsx(
  fontLufga.variable,
  fontPTSerif.variable,
  'font-sans',
)

export default function App({ Component, pageProps, router }: AppProps) {
  const [queryClient] = useState(() => new QueryClient())

  if (typeof window !== 'undefined' && router.isReady) {
    router.events.on('routeChangeStart', () => {
      sessionStorage.setItem('referrer', router.asPath)
    })
  }

  return (
    <>
      {/* Cookie information */}
      <Script
        id={'CookieConsent'}
        strategy={'beforeInteractive'}
        src={'https://policy.app.cookieinformation.com/uc.js'}
        data-culture={'DA'}
        type={'text/javascript'}
        data-gcm-version={'2.0'}
      />

      {/* Google Analytics */}
      <Script
        id={'google-tag-manager'}
        data-category-consent={'cookie_cat_statistic'}
        strategy={'afterInteractive'}
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NM77J9P');`,
        }}
      />

      <DefaultSeo {...defaultSeo} />

      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <AnimatePresence initial={false} mode={'wait'}>
            <Component {...pageProps} key={router.pathname} />
          </AnimatePresence>
        </Hydrate>

        <ReactQueryDevtools position={'bottom-right'} />
      </QueryClientProvider>
    </>
  )
}
